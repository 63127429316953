@font-face {
  font-family: "Noto Sans";
  src: url("https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i");
}
a:link {
  color: #c7a338;
}
body {
  padding: 0;
  background: #e4e4e4;
}
.main-container {
  width: 700px;
  margin: auto;
  background: white;
  border-top: 7px #02adc1 solid;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.35);
}
.main-container .content {
  margin: 40px 49px 30px 48px;
}
.main-container .content h1 {
  font-family: "NotoSans", helvetica, arial, sans-serif;
  font-size: 27px;
  color: #222;
  text-align: left;
  line-height: 22px;
  letter-spacing: -0.01em;
}
.main-container .content p {
  font-family: "NotoSans", helvetica, arial, sans-serif;
  font-size: 17px;
  color: #666;
  text-align: left;
  line-height: 25px;
}
.main-container .footer {
  position: relative;
  height: 45px;
}
.main-container .footer img {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.checkbox-style {
  width: 12px;
  height: 12px;
  background: #b2b2b2;
}
.mobile {
  display: block;
}
input[type="submit"] {
  -webkit-appearance: none;
  background-color: #c7a338;
  width: 172px;
  height: 48px;
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  border: none;
  color: #232323;
  padding: 12px 12px 12px 6px;
  text-decoration: none;
  cursor: pointer;
  margin-top: 30px;
}

a:visited {
  text-decoration: none;
  decoration: none;
  color: inherit;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .mobile {
    display: inline;
  }
}
